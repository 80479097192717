<template>
  <GenAILayout class="intent-selection">
    <template #nav>
      <cvt-top-nav-bar
        mode="LIGHT"
        :route="genAISelectTemplate"
        variant="embedded"
        :class="'cvt-top-nav-bar'"
        template-name="Let's get started!"
        :on-genai-wizard="true"
        :fixed="true"
        :light-logo="logo"
        :dark-logo="logo"
        :type="orgName"
        @switch-preview="switchViewMode"
        @use-template="() => useTemplate()"
        @back="navigateToChooseATemplate"
      />
    </template>

    <cvt-left-side-bar class="marketplace-left-nav-bar intent-sidebar">
      <cvt-button
        :loading="loading"
        class="back-btn"
        text=""
        icon="cvt-icon-arrow-left-05"
        @click="goBack"
      />

      <div class="mt-5">
        <p>{{ $t('element.property.theme.brand.preference') }}</p>
        
        <cvt-select
          :key="brandingThemeList.length"
          class="w-100 mb-4"
          mode="dropdown"
          :background-mode="mode"
          :multiple="false"
          :value="selectedBrandTheme"
          :placeholder="'Select one'"
          @input="(value) => onSelectBrandTheme(value)"
        >
          <option
            v-for="data in brandingThemeList"
            :key="data.idx"
            :value="data.idx"
            v-text="data.brandingName"
          ></option>

          <optgroup
            :key="'...'"
            :label="'...'"
          >
            <option
              v-for="newTheme in ['+ Create new theme']"
              :key="newTheme"
              :value="newTheme"
              v-text="newTheme"
            ></option>
          </optgroup>
        </cvt-select>

        <div class="sidebar-horizontal-line"></div>
      </div>

      <div v-if="selectedBrandThemeData">
        <div>
          <p class="mt-4" style="font-weight: 700;">{{ $t('editor.left_nav.element.logo') }}</p>
        
          <p>
            <img 
              style="height: 50px;"
              :src="selectedBrandThemeData.brandLogo"
              alt="brand-logo"
            />
          </p>
        </div>

        <div>
          <p class="mt-4" style="font-weight: 700;">{{ $t('element.property.color.palette') }}</p>
          <p class="mt-n2">{{ $t('element.property.branding.primary.colors') }}</p>

          <div class="d-flex flex-wrap">
            <div
              v-for="(color, idx) in selectedBrandThemeData.theme.colors"
              :key="idx"
              class="sidebar-color-palette"
              :style="colorToStyle(color)"
            ></div>
          </div>
        </div>

        <div>
          <p class="mt-4" style="font-weight: 700;">{{ $t('element.property.typography') }}</p>

          <div class="mt-n2 mb-4">
            <div>Header - <span :style="selectedThemeTypography.headerStyles">{{ selectedThemeTypography.headerStyles['font-family'] }}</span></div>
            <div>Body - <span :style="selectedThemeTypography.bodyStyles">{{ selectedThemeTypography.bodyStyles['font-family'] }}</span></div>
          </div>

          <div class="sidebar-horizontal-line"></div>
        </div>
      </div>

      <div v-else class="d-flex justify-content-center align-items-center" style="height: 500px;"> 
        <p style="font-weight: 600;">{{ $t('element.property.branding.no.theme.selected') }}</p>
      </div>

      <cvt-button
        :loading="loading"
        class="mt-5 magic-btn"
        :text="$t('gen_ai.template.continue_btn')"
        icon="cvt-icon-arrow-refresh-06"
        :prepend="true"
        @click="switchTemplate"
      />


    </cvt-left-side-bar>
    <div class="content-wrapper position-relative">
      <div v-if="loading" class="working-on-block">
        <div class="working-on-block__container">
          <h3 class="loader-message">{{loadingMessage}}</h3>
        </div>
      </div>
      <div class="w-100, h-100">
        <div :class='{"content": true, "d-none": currentAPIData.previewURL === "", "mobile": viewMode !== "desktop"}'>
          <iframe
            :key="currentAPIData.previewURL"
            :src="currentAPIData.previewURL"
            frameborder={0}
            @load="iframeLoaded"
          />
        </div>
      </div>
    </div>

    <cvt-dialog :show="modalVisible" :hide-borders="true" :width="'90%'" @close="onCloseModal">
      <div class="integrations-wrapper">
        <marketplace-branding />
      </div>
    </cvt-dialog>
  </GenAILayout>
</template>

<script>
import { cvtRouteNodes, cvtRoutes } from "../../utils/routes";
import { getGenAITemplate, getSessionItemGenAIOnboarding } from "./utils";
import { isNavigationFailure, NavigationFailureType } from "vue-router";
import { mapActions, mapGetters, mapState } from "vuex";
import * as actions from "@/js/store/modules/actions";
import { Notification } from "element-ui";
import { V1DynamicContent, V1Sites, Marketplace } from "@/js/store/services/api";
import color from "color";
import * as getters from "@/js/store/modules/getters";

import GenAILayout from './baseLayout.vue'
import MarketplaceBranding from "../marketplace/branding/index.vue";

const dcAPI = new V1DynamicContent()
const sites = new V1Sites()
const marketplace = new Marketplace()

export default {
  name: 'SelectTemplate',
  components: {
    GenAILayout,
    MarketplaceBranding
  },
  props: {
    // Your props here
  },
  data() {
    const {
      genAISelectTemplate,
    } = cvtRouteNodes
    return {
      templates: [
        'This is a template',
        'This is another template',
        'Another template here',
      ],
      mode: 'LIGHT',
      activeTemplate: 0,
      loading: false,
      genAISelectTemplate,
      currentAPIData: {name: "", pageId: "", siteId: "", siteRef: "", state: "", thumbnail: "", previewId: "", previewURL: ""},
      viewMode: 'desktop',
      loadingMessage: 'Please Wait',
      genAiTemplateList: [],
      usedTemplates: [],
      brandThemeArray: ['Convrrt', 'Infusionsoft', 'Integrate', 'Instamojo', 'SendInBlue'],
      selectedBrandTheme: '',
      selectedBrandThemeData: null,
      modalVisible: false,
      brandingThemeList: null,
    }
  },
  computed: {
    // Your computed properties here
    ...mapGetters({
      theme: getters.AUTH_GET_USER_BUILDER_THEME,
      brandingData: getters.MP_GET_BRANDS,
    }),
    ...mapState('globalTheme', {
      orgName: ({ globalTheme }) => globalTheme.OrgName,
    }),
    navColor() {
      if (this.theme.color) {
        const colorPrefix = [3, 6].includes(this.theme.color.length) ? '#' : ''
        return color(`${colorPrefix}${this.theme.color}`)
      }
      return ''
    },
    logo() {
      return this.navColor && this.navColor.dark()
        ? this.theme.logoAlt
        : this.theme.logo
    },
    selectedThemeTypography() {
      if (!this.selectedBrandThemeData || !this.selectedBrandThemeData.theme || !this.selectedBrandThemeData.theme.typography) {
        return null;
      }
      const { typography } = this.selectedBrandThemeData.theme
      return {
        headerStyles: {
          'font-family': typography.heading.font,
          'font-size': '20px',
          'color': typography.heading.defaultTextColor,
          'font-weight': typography.heading.weight,
          'text-transform': 'capitalize',
        },
        bodyStyles: {
          'font-family': typography.normalText.font,
          'font-size': '20px',
          'color': typography.normalText.defaultTextColor,
          'font-weight': typography.normalText.weight,
          'text-transform': 'capitalize',
        }
      }
    }
  },
  watch: {
    brandingData: {
      handler(newBrandingData) {
        this.brandingThemeList = newBrandingData
      },
      immediate: true,
      deep: true
    }
  },
  async mounted() {
    await this.initBrandManager()
    this.initialContentLoading()
    this.genAiTemplateList = []
  },
  methods: {
    ...mapActions({
      mixpanelTrack: actions.MIXPANEL_TRACK,
      initBrandManager: actions.MP_INIT_BRAND_MANAGER
    }),
    goBack() {
      this.$router.push(cvtRoutes.genAIIntent.path)
    },
    startLoading(value, message='Please Wait...') {
      this.loading = value
      this.loadingMessage = message
    },
    async switchTemplate() {
      try {
        this.startLoading(true, 'Regenerating Template...')

        if (!this.genAiTemplateList.length) {
          const selectedIntentGoal = getSessionItemGenAIOnboarding('intentGoal')
          const selectedIntent = getSessionItemGenAIOnboarding('intent')

          const params = {
            intent: [selectedIntent],
            intentGoal: [selectedIntentGoal]
          }
          const res = await marketplace.genAiTemplates(params)
          this.genAiTemplateList = res
          this.usedTemplates = []
        }

        if (this.genAiTemplateList.length) {
          let availableTemplates = this.genAiTemplateList.filter(
            template => !this.usedTemplates.includes(template.pageId)
          )
          if (!availableTemplates.length) {
            // If all templates have been used, reset the usedTemplates list
            this.usedTemplates = []
            availableTemplates = [...this.genAiTemplateList]
          }

          const selectedTemplate = availableTemplates[Math.floor(Math.random() * availableTemplates.length)]
          this.usedTemplates.push(selectedTemplate.pageId)
          const previewURL = `${window.location.origin}/api/v1/sites/${selectedTemplate.siteId}/preview/${selectedTemplate.pageId}`

          // Update the currentAPIData
          this.currentAPIData = Object.assign(
            {},
            this.currentAPIData,
            selectedTemplate,
            {
              previewURL,
              previewId: selectedTemplate.siteKey
            }
          )

        } else {
          this.startLoading(false)
          return Notification({
            title: 'No Template',
            message: 'No templates found for that intent',
            type: 'info',
            position: 'top-right',
          })
        }

      } catch (err) {
        this.startLoading(false)
        throw err
      }
    },
    iframeLoaded() {
      this.startLoading(false)
    },
    async createSiteFromPreviewID (previewId) {
      const req = dcAPI.selectTemplate({previewId: previewId})
      // const { data } = resp
      // const { id } = data
      return await req.catch((err) => {
        console.error('failed to create site', err)
        const { response = {} } = err
        const { data } = response
        if (data && data.errors) {
          for (const e of data.errors) {
            Notification({
              title: e.code || 'woops',
              message: e.message,
              type: 'error',
              position: 'bottom-right',
            })
          }
        }
        throw err
      })
    },
    switchViewMode (currentSelected) {
      if (!this.currentAPIData.previewURL) return
      this.viewMode = currentSelected
      this.startLoading(true, 'Switching View Mode...')
      if (currentSelected === 'desktop') {
        this.currentAPIData = {...this.currentAPIData, previewURL: dcAPI.getPreviewURLAlt(this.currentAPIData.previewURL)}
        this.startLoading(false)
      } else {
        this.currentAPIData = {...this.currentAPIData, previewURL: dcAPI.getPreviewURLAlt(this.currentAPIData.previewURL, true)}
      }
    },
    printError(message) {
      Notification({
        title: 'woops',
        message: message,
        type: 'error',
        position: 'bottom-right',
      })
    },
    async useTemplate() {
      const selectedIntentGoal = getSessionItemGenAIOnboarding('intentGoal')
      const selectedIntent = getSessionItemGenAIOnboarding('intent')
      const description = getSessionItemGenAIOnboarding('businessDescription')
      const industry = getSessionItemGenAIOnboarding('industry')

      const params = {
        businessDescription: description,
        businessSummary: industry,
        intent: [selectedIntent],
        intentGoal: [selectedIntentGoal]
      }

      if (this.currentAPIData.previewId !== ""  && !this.loading) {
        this.startLoading(true, 'Switching to builder...')

        try {
          const siteResp = await sites.fromGenAiTemplate(this.currentAPIData.siteId, params)

          this.$router.push({
            name: cvtRouteNodes.editorDesign,
            params: {
              siteId: siteResp.id,
            },
          })
          this.mixpanelTrack({
            event: 'Generative AI Template',
            category: 'gen-ai-templates',
            data: { template_id: siteResp.id },
          })
          this.startLoading(false)
        } catch (e) {
          this.startLoading(false)
          if (!isNavigationFailure(e, NavigationFailureType.redirected)) {
            throw e
          }
        }
      } else {
        if (this.loading) {
          return Notification({
            title: 'In Progress',
            message: 'We are processing the template.. Please Wait.',
            type: 'info',
            position: 'bottom-right',
          })
        }
        this.printError('Onboarding did not follow due process')
      }
    },
    async initialContentLoading () {
      const template = getGenAITemplate()
      if (template) {
        this.startLoading(true, 'Rendering Template...')
        const templatesArray = JSON.parse(atob(template))
        if (templatesArray.length) {
          const firstTemplate = templatesArray[0]
          const previewURL = `${window.location.origin}/api/v1/sites/${firstTemplate.siteId}/preview/${firstTemplate.pageId}`
          this.currentAPIData = {...firstTemplate, previewURL, previewId: firstTemplate.siteKey}
          return
        } else {
          this.startLoading(false)
          return Notification({
            title: 'No Template',
            message: 'No templates found for that intent',
            type: 'info',
            position: 'top-right',
          })
        }
      }
      this.printError('Onboarding did not follow due process')
    },
    navigateToChooseATemplate() {
      console.log("navigateToChooseATemplate clicked")
      this.$router.push({
        name: cvtRouteNodes.chooseAtemplate,
      })
    },
    onSelectBrandTheme(value) {
      this.selectedBrandTheme = value
      if (this.selectedBrandTheme === '+ Create new theme') {
        return this.modalVisible = true
      }
      this.selectedBrandThemeData = this.brandingThemeList.find(theme => theme.idx === this.selectedBrandTheme)
    },
    async onCloseModal() {
      await this.initBrandManager()
      this.modalVisible = false
    },
    colorToStyle(color) {
      return {
        backgroundColor: color.hex,
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import './styles/IntentSelection.style.scss';
</style>
