
<GenAILayout class="intent-selection">
  <template #nav>
    <cvt-top-nav-bar
      mode="LIGHT"
      :route="genAISelectTemplate"
      variant="embedded"
      :class="'cvt-top-nav-bar'"
      template-name="Let's get started!"
      :on-genai-wizard="true"
      :fixed="true"
      :light-logo="logo"
      :dark-logo="logo"
      :type="orgName"
      @switch-preview="switchViewMode"
      @use-template="() => useTemplate()"
      @back="navigateToChooseATemplate"
    />
  </template>

  <cvt-left-side-bar class="marketplace-left-nav-bar intent-sidebar">
    <cvt-button
      :loading="loading"
      class="back-btn"
      text=""
      icon="cvt-icon-arrow-left-05"
      @click="goBack"
    />

    <div class="mt-5">
      <p>{{ $t('element.property.theme.brand.preference') }}</p>
      
      <cvt-select
        :key="brandingThemeList.length"
        class="w-100 mb-4"
        mode="dropdown"
        :background-mode="mode"
        :multiple="false"
        :value="selectedBrandTheme"
        :placeholder="'Select one'"
        @input="(value) => onSelectBrandTheme(value)"
      >
        <option
          v-for="data in brandingThemeList"
          :key="data.idx"
          :value="data.idx"
          v-text="data.brandingName"
        ></option>

        <optgroup
          :key="'...'"
          :label="'...'"
        >
          <option
            v-for="newTheme in ['+ Create new theme']"
            :key="newTheme"
            :value="newTheme"
            v-text="newTheme"
          ></option>
        </optgroup>
      </cvt-select>

      <div class="sidebar-horizontal-line"></div>
    </div>

    <div v-if="selectedBrandThemeData">
      <div>
        <p class="mt-4" style="font-weight: 700;">{{ $t('editor.left_nav.element.logo') }}</p>
      
        <p>
          <img 
            style="height: 50px;"
            :src="selectedBrandThemeData.brandLogo"
            alt="brand-logo"
          />
        </p>
      </div>

      <div>
        <p class="mt-4" style="font-weight: 700;">{{ $t('element.property.color.palette') }}</p>
        <p class="mt-n2">{{ $t('element.property.branding.primary.colors') }}</p>

        <div class="d-flex flex-wrap">
          <div
            v-for="(color, idx) in selectedBrandThemeData.theme.colors"
            :key="idx"
            class="sidebar-color-palette"
            :style="colorToStyle(color)"
          ></div>
        </div>
      </div>

      <div>
        <p class="mt-4" style="font-weight: 700;">{{ $t('element.property.typography') }}</p>

        <div class="mt-n2 mb-4">
          <div>Header - <span :style="selectedThemeTypography.headerStyles">{{ selectedThemeTypography.headerStyles['font-family'] }}</span></div>
          <div>Body - <span :style="selectedThemeTypography.bodyStyles">{{ selectedThemeTypography.bodyStyles['font-family'] }}</span></div>
        </div>

        <div class="sidebar-horizontal-line"></div>
      </div>
    </div>

    <div v-else class="d-flex justify-content-center align-items-center" style="height: 500px;"> 
      <p style="font-weight: 600;">{{ $t('element.property.branding.no.theme.selected') }}</p>
    </div>

    <cvt-button
      :loading="loading"
      class="mt-5 magic-btn"
      :text="$t('gen_ai.template.continue_btn')"
      icon="cvt-icon-arrow-refresh-06"
      :prepend="true"
      @click="switchTemplate"
    />


  </cvt-left-side-bar>
  <div class="content-wrapper position-relative">
    <div v-if="loading" class="working-on-block">
      <div class="working-on-block__container">
        <h3 class="loader-message">{{loadingMessage}}</h3>
      </div>
    </div>
    <div class="w-100, h-100">
      <div :class='{"content": true, "d-none": currentAPIData.previewURL === "", "mobile": viewMode !== "desktop"}'>
        <iframe
          :key="currentAPIData.previewURL"
          :src="currentAPIData.previewURL"
          frameborder={0}
          @load="iframeLoaded"
        />
      </div>
    </div>
  </div>

  <cvt-dialog :show="modalVisible" :hide-borders="true" :width="'90%'" @close="onCloseModal">
    <div class="integrations-wrapper">
      <marketplace-branding />
    </div>
  </cvt-dialog>
</GenAILayout>
